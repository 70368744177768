import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { map, catchError, share } from 'rxjs/operators';
import { ApiService } from './api.service';


export interface AIOptions {
  context?: string[];
  companies?: boolean;
  people?: boolean;
  properties?: string[];
  model?: string;
  temperature?: number;
}

export interface AISearchOptions {
  criteria: string;
  options: AIOptions;
}

export interface AIChatOptions {
  messages: {
    role: string;
    content: string;
  }[];
  options?: AIOptions;
  stream?: boolean;
  action?: string;
  cityId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AiApiService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  public createSearch(payload: AIChatOptions) {
    return this.apiService.post({
      resource: 'ai/create-search',
      payload,
    });
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ICity, IPost } from '../shared/interfaces';
import { AccountService } from './account-service';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import { DistrictService } from './district.service';
import { FieldService } from './field.service';
import { appInjector } from '../app.module';
import { BroadcastService } from './broadcast.service';
import { UserService } from './user.service';
import { LoginService } from './login-service';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  public allCities: ICity[] = [];
  public currentCityId: number;

  constructor(
    private broadcastService: BroadcastService,
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public async changeCurrentCity() {
    if (this.currentCityId) {
      // set currentCityId to user
      const userService = appInjector.get(UserService);
      const loginService = appInjector.get(LoginService);
      if (loginService.currentUser) {
        loginService.currentUser.cityId = this.currentCityId;
        userService.update({
          id: loginService.currentUser.id,
          cityId: this.currentCityId,
        }).toPromise().then(() => {});
      }
      localStorage.setItem('currentCityId', '' + this.currentCityId);

      // get districts, fields
      const filter: IFilter = {};
      filter.where = {
        cityId: this.currentCityId,
      };
      const districtService = appInjector.get(DistrictService);
      districtService.allDistricts = await districtService.getDistricts(filter).toPromise();
      const fieldService = appInjector.get(FieldService);
      await fieldService.getFields({
        where: {}, // auto include districts
      }, true).toPromise();

      this.broadcastService.broadcast('changeCurrentCity', {
        cityId: this.currentCityId,
      });
    }
  }

  public getCities(filter: IFilter): Observable<ICity[]> {
    const $this = this;
    const setting: ISetting = {
      resource: 'cities',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
      map((res: any) => res),
        catchError((errGetCitys) => {
          console.log('==== errGetCitys :', errGetCitys);
          return this.exceptionService.catchBadResponse(errGetCitys);
        }),
        share(),
      );
  }

}

import {
  Component, OnInit, Input, TemplateRef, Output, EventEmitter,
} from '@angular/core';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { UserService } from 'src/app/services/user.service';
import { IAffiliate, IUser } from '../../interfaces';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss'],
})
export class AffiliateComponent implements OnInit {
  @Input() affiliate: IAffiliate;

  private subscriptions = [];

  constructor(
    private broadcastService: BroadcastService,
    public userService: UserService,
  ) {
    const subscription = this.broadcastService.subjectUniversal.subscribe((msg) => {
    });
    this.subscriptions.push(subscription);
  }
  ngOnInit() {
    //
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}

import {
  Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation, ElementRef, Output, EventEmitter,
} from '@angular/core';

import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AiApiService } from 'src/app/services/ai.api.service';
import { IPost, IPostResult } from '../../interfaces';
import { IFilter } from 'src/app/services/api.service';
import { Platform } from '@ionic/angular';
import { SpeechRecognition } from '@ionic-native/speech-recognition';
import { CityService } from 'src/app/services/city.service';
import { LoginService } from 'src/app/services/login-service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UserService } from 'src/app/services/user.service';

interface IChatAnswer {
  class: string;
  response: string;
  html?: string;
}

@Component({
  selector: 'app-research-ai-filter',
  templateUrl: './research-ai-filter.component.html',
  styleUrls: ['./research-ai-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ResearchAIFilterComponent implements OnInit, OnDestroy {
  @Input() public mode = 'search'; // create
  @Input() public postType = '';
  @Input() public filter: IFilter;
  @Input() public isAdminView: boolean;
  @Input() public aiContext: {
    content: string;
    role: string;
  }[] = [];
  @Output() public createSearch = new EventEmitter<{
    postResult: IPostResult;
    action: string;
    searchText?: string;
  }>();

  public placeholderText = '';
  public filterLength = 0;
  public defaultFilterLength = 4; // cityId, postType, status, districts
  public searchText ='';
  public isLoading = false;
  public chatAnswers: IChatAnswer[] = [];
  public currentMessage = 'Tìm đối Yếu 17h30 hôm nay Quận Cầu Giấy';

  private subscriptions: Subscription[] = [];
  private letterIndex = 0;
  private typingInterval: any;
  private isDeleting = false;
  private typingSpeed = 25; // milliseconds

  constructor(
    private aiApiService: AiApiService,
    private platform: Platform,
    private cityService: CityService,
    public loginService: LoginService,
    public userService: UserService,
    public utilitiesService: UtilitiesService,
    private broadcastService: BroadcastService,
  ) {
    const subscription = this.broadcastService.subjectUniversal.subscribe((msg) => {
      if (msg.name === 'changeCurrentCity') {
        this.setCurrentMessage();
        if (this.typingInterval) {
          clearInterval(this.typingInterval);
        }
        this.typingInterval = setInterval(() => this.type(), this.typingSpeed);
      }
    });
    this.subscriptions.push(subscription);
  }

  public ngOnInit() {
    this.countFilterLength(this.filter);
    this.setCurrentMessage();
    this.typingInterval = setInterval(() => this.type(), this.typingSpeed);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (this.typingInterval) {
      clearInterval(this.typingInterval);
    }
  }


  public reset() {
    this.createSearch.emit({
      action: 'reset',
      postResult: {},
    });
  }

  public startListening() {
    this.platform.ready().then(() => {
      const options = {
        language: 'vi-VN'
      };
      SpeechRecognition.startListening(options)
        .subscribe(
          (matches: string[]) => {
            console.log('==== matches :' + matches);
            if (matches?.length > 0) {
              this.searchText = matches[0];
              this.runAIWithText('search').then(() => {});
            }
          },
          (onerror) => {
            // alert('Bạn cần cho phép ứng dụng Football Partners dùng microphone bằng cách vào Cài Đặt của điện thoại');
            if (onerror && onerror.indexOf('Missing permission') > -1) {
              SpeechRecognition.requestPermission().then(() => {
                this.startListening();
              });
            } else {
              console.log('==== onerror :' + onerror);
            }
          }
        );
    });
  }

  // text: user click to the Example search text;
  public async runAIWithText(action, text?: string) {
    this.countFilterLength(this.filter);
    if (action === 'search' &&
      ((this.isAdminView && this.filterLength > 1) || (!this.isAdminView && this.filterLength > this.defaultFilterLength))) {
      this.createSearch.emit({
        action: 'modal-search',
        postResult: {},
      });
      return;
    }

    if (text) {
      this.searchText = text;
    }
    if (!this.searchText || this.searchText.trim().length === 0) {
      this.createSearch.emit({
        postResult: {},
        action,
      });
      return;
    }
    this.chatAnswers = [];
    this.chatAnswers.push({
      class: 'user',
      response: this.searchText,
    });
    const messages = [];
    this.chatAnswers.forEach((chatAnswer, index) => {
      messages.push({
        content: chatAnswer.response,
        role: chatAnswer.class,
      });
    });

    this.aiApiService.createSearch({
      messages,
      options: {
        model: 'gpt-4',
        temperature: 0.7,
      },
      action,
      cityId: this.cityService.currentCityId,
    }).pipe(take(1)).subscribe((postResult: any) => {
      try {
        if (postResult === false || !postResult.post) {
          alert('Không thấy kết quả. Xin mời thử lại sau');
          return Promise.resolve();
        }
        const postFilter = postResult.post;
        const resKeys = Object.keys(postFilter);
        if (resKeys.length === 0) {
          alert('Không thấy kết quả nào. Xin mời bạn thử lại');
          return Promise.resolve();
        }
        this.createSearch.emit({
          postResult,
          action,
          searchText: this.searchText,
        });
      } catch (ex) {
        console.error('error setFilterAI');
        return Promise.reject(ex);
      }
    });
  }

  private countFilterLength(filterParam: IFilter) {
    this.filterLength = 0;
    const whereKeys = Object.keys(filterParam.where);
    if (whereKeys && whereKeys.length > 0) {
      whereKeys.forEach((whereKeyItem) => {
        if (whereKeyItem !== 'lookups' && filterParam.where[whereKeyItem] &&
          (typeof filterParam.where[whereKeyItem] === 'number' || filterParam.where[whereKeyItem].length > 0)) {
          this.filterLength += 1;
        }
      });
    }
  }

  private setCurrentMessage() {
    let districtName = 'Cầu Giấy';
    if (this.cityService.currentCityId === 2) {
      districtName = 'Hải Châu';
    } if (this.cityService.currentCityId === 3) {
      districtName = '1';
    } if (this.cityService.currentCityId === 4) {
      districtName = 'Thủ Đức';
    }
    if (this.postType === 'partner') {
      this.currentMessage = 'Tìm đối Yếu 17h30 hôm nay Quận ' + districtName;
    } else if (this.postType === 'player') {
      this.currentMessage = 'Tìm thêm người ở Quận ' + districtName + ' hôm nay';
    } else if (this.postType === 'team') {
      this.currentMessage = 'Tìm đội khu ' + districtName + ', trình độ: trung bình. liên hệ: 0123456789';
    } else if (this.postType === 'field') {
      this.currentMessage = 'Tìm sân 17h45 ở Quận ' + districtName;
    }
  }

  private type() {
    const currentText = this.currentMessage;
    if (!currentText || currentText.length === 0) {
      return;
    }
    const updatedText = currentText.substring(0, this.letterIndex);

    if (this.isDeleting) {
      this.letterIndex -= 1;
    } else {
      this.letterIndex += 1;
    }

    this.placeholderText = updatedText;

    if (!this.isDeleting && this.letterIndex === currentText.length) {
      // Start deleting after a pause
      try {
        setTimeout(() => this.isDeleting = true, 2000);
      } catch (errType) {
        console.log('=== errType:', errType);
      }
    } else if (this.isDeleting && this.letterIndex === 0) {
      this.isDeleting = false;
    }
  }
}

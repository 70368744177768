import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { IDistrict, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import { CityService } from './city.service';

@Injectable({
  providedIn: 'root',
})
export class DistrictService {
  public allDistricts: IDistrict[] = [];

  constructor(
    private apiService: ApiService,
    private cityService: CityService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getDistricts(filter: IFilter): Observable<IDistrict[]> {
    const $this = this;
    if (filter.where.cityId && filter.where.cityId !== this.cityService.currentCityId) {
      $this.cityService.currentCityId = filter.where.cityId;
      this.cityService.changeCurrentCity().then(() => {});
    }
    const setting: ISetting = {
      resource: 'districts',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
        map((res: any) => res),
        catchError((errGetDistricts) => {
          console.log('==== errGetDistricts :', errGetDistricts);
          return this.exceptionService.catchBadResponse(errGetDistricts);
        }),
        share(),
      );
  }

}

import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.html',
  styleUrls: ['./confirm-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ConfirmModalComponent implements OnDestroy, OnInit {
  @ViewChild('inputText') myInput;

  public confirmText = null;

  constructor(
    private modalController: ModalController,
  ) {
    const $this = this;
  }

  public ngOnInit() {
  }

  ionViewDidEnter() {
    setTimeout(() => {
      // this.myInput.setFocus();
    }, 150);

  }
  async dismiss() {
    await this.modalController.dismiss();
  }

  async post() {
    await this.modalController.dismiss({
      confirmText: 'y',
    });
  }

  public ngOnDestroy() {
  }

}

import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { appInjector } from '../app.module';
import { AppEvent, AppStorageKey } from '../models/enums/app-constant';
import { IUser } from '../shared/interfaces';
import { LoginComponent } from '../shared/modal/login/login.component';
import { LoginService } from './login-service';
import { UtilitiesService } from './utilities.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public userProvider = null; // user from providers (Google, facebook).

    constructor(
        public storage: NativeStorage,
        // public storage2: Storage,
        private utilitiesService: UtilitiesService,
    ) { }

    async login(user): Promise<any> {
        this.userProvider = user;
        if (this.utilitiesService.currentPlatform === 'cordova') {
            await this.storage.setItem(AppStorageKey.currentUser, user);
        }
        return window.dispatchEvent(new CustomEvent(AppEvent.login, { detail: user }));
    }

    async logout(): Promise<any> {
        this.userProvider = null;
        if (this.utilitiesService.currentPlatform === 'cordova') {
            await this.storage.remove(AppStorageKey.currentUser);
        }
        window.dispatchEvent(new CustomEvent(AppEvent.logout));
        return Promise.resolve();
    }

    async getUser(): Promise<IUser | undefined> {
        if (this.utilitiesService.currentPlatform === 'cordova') {
            const user = await this.storage.getItem(AppStorageKey.currentUser);
            if (!user) { return undefined; }
            return { name: user.name, email: user.email, avatar: user.avatar };
        }
        return undefined;
    }

    async isLoggedIn(): Promise<boolean> {
        return await this.getUser() !== undefined;
    }

    public showlogin() {
        return new Observable(observer => {
            if (this.checkLoggedIn()) {
                observer.next();
            } else {
                const loginService = appInjector.get(LoginService);
                loginService.loginViaGoogle().then(() => {
                    observer.next();
                });

                // this.modalController.create({
                //     component: LoginComponent,
                // }).then((modal) => {
                //     modal.present();
                //     modal.onDidDismiss().then((loginType) => {
                //         observer.next(loginType);
                //     });
                // });
            }
        });
    }

    public checkLoggedIn(): boolean {
        if (this.userProvider) {
            return true;
        }
        return  false;
    }
}

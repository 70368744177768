import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AccountService } from './services/account-service';
import { ApiService } from './services/api.service';
import { CityService } from './services/city.service';
import { DistrictService } from './services/district.service';
import { FieldService } from './services/field.service';
import { LevelService } from './services/level.service';
import { LocalStorageService } from './services/local-storage-service';
import { LoginService } from './services/login-service';
import { UserService } from './services/user.service';
import { UtilitiesService } from './services/utilities.service';
import { AffiliateService } from './services/affiliate.service';
import { BroadcastService } from './services/broadcast.service';
import { OthersService } from './services/others.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {

  public loading = false;
  private subscriptions = [];

  constructor(
    public userService: UserService,
    private platform: Platform,
    private menu: MenuController,
    private router: Router,
    private districtService: DistrictService,
    private levelService: LevelService,
    private fieldService: FieldService,
    public cityService: CityService,
    public affiliateService: AffiliateService,
    public othersService: OthersService,
    private accountService: AccountService,
    public utilitiesService: UtilitiesService,
    public geo: Geolocation,
    public loginService: LoginService,
    private sanitized: DomSanitizer) {
    this.initializeApp().then(() => {});
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.router.url.endsWith('/')) {
        this.router.navigate(['/tabs/tim-doi']);
      }
    }, 500);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public login() {
    this.accountService.showlogin().subscribe(() => {
      this.menu.close();
      this.router.navigate(['/tabs/tim-doi'], { queryParams: { view: 'help' } });
    });
  }

  public logout() {
    this.loginService.logout().then(() => {
      this.router.navigate(['/tabs/tim-doi'], { queryParams: { view: 'help' } });
    });
  }

  public showHelp() {
    this.menu.close();
    this.router.navigate(['/tabs/thong-bao'], { queryParams: { view: 'help' } });
  }

  public admin() {
    this.menu.close();
    this.router.navigate(['/tabs/thong-bao/admin']);
  }

  public watchVideo(video) {
    this.router.navigate(['/tabs/thong-bao'], { queryParams: {
      videoId: video.videoId,
      view: 'video',
    } });
  }

  public personal() {
    this.menu.close();
    this.router.navigate(['/tabs/thong-bao/ca-nhan']);
  }

  private async initializeApp() {
    this.platform.ready().then(async (resPlatform) => {
      this.utilitiesService.isLoadingRequiredData = true;
      this.utilitiesService.isWeb = !(this.platform.is('android') || this.platform.is('ios'));
      this.utilitiesService.currentPlatform = resPlatform; // cordova, dom
      this.utilitiesService.checkMobileView();
      this.loading = true;
      await this.loginService.refreshToken();

      if (this.loginService.currentUser?.cityId) {
        this.cityService.currentCityId = this.loginService.currentUser.cityId;
      } else {
        const currentCityIdStoraged = localStorage.getItem('currentCityId');
        if (currentCityIdStoraged) {
          this.cityService.currentCityId = Number.parseInt(currentCityIdStoraged, 10);
        }
      }

      // if (!this.cityService.currentCityId && resPlatform === 'cordova') {
      //   await this.getCurrentLocation();
      // }

      if (!this.cityService.currentCityId) {
        this.cityService.currentCityId = 1;
      }

      this.affiliateService.getAffiliates({
        where: {
        }
      }).toPromise().then((resAffiliates) => {
        this.affiliateService.allAffiliates = resAffiliates;
        this.affiliateService.leftAffiliates = [];
        this.affiliateService.rightAffiliates = [];
        this.affiliateService.centerAffiliates = [];
        this.affiliateService.allAffiliates.forEach((allAffiliatesItem) => {
          if (allAffiliatesItem.position === 'left') {
            this.affiliateService.leftAffiliates.push(allAffiliatesItem);
          } else if (allAffiliatesItem.position === 'right') {
            this.affiliateService.rightAffiliates.push(allAffiliatesItem);
          } else if (allAffiliatesItem.position === 'center') {
            this.affiliateService.centerAffiliates.push(allAffiliatesItem);
          }
        });
      });


      this.othersService.getVideos().toPromise().then((resVideos) => {
        this.othersService.allVideos = resVideos;
        if (this.othersService.allVideos?.length > 0) {
          this.othersService.allVideos.forEach((video) => {
            const videoUrl = 'https://www.youtube.com/embed/' + video.videoId + '?autoplay=1';
            video.url = this.sanitized.bypassSecurityTrustResourceUrl(videoUrl);
          });
        }
      });

      this.cityService.allCities = await this.cityService.getCities({
        where: {
        }
      }).toPromise();

      this.districtService.allDistricts = await this.districtService.getDistricts({
        where: {
          cityId: this.cityService.currentCityId,
        }
      }).toPromise();

      await this.fieldService.getFields({
        where: {}, // auto include districts
      }, true).toPromise();

      this.levelService.allLevels = await this.levelService.getLevels({}).toPromise();
      this.loading = false;
      this.utilitiesService.isLoadingRequiredData = false;
    });
  }

  // private async getCurrentLocation() {
  //   try {
  //     const pos = await this.geo.getCurrentPosition();
  //     const latitude = pos.coords.latitude;
  //     const longitude = pos.coords.longitude;

  //     // ==== Duy Tân: latitude: 21.045045045045043 : longitude : 105.79546616122295
  //     if (latitude > 19) {
  //       this.cityService.currentCityId = 1; // Hà Nội
  //     } else if (latitude > 14) {
  //       this.cityService.currentCityId = 2; // Đà Nẵng
  //     } else if (latitude > 9) {
  //       if (latitude > 10.84) {
  //         this.cityService.currentCityId = 4; // TP Thủ Đức
  //       } else {
  //         this.cityService.currentCityId = 3; // TP Hồ Chí Minh - 10.825132
  //       }
  //     }
  //   } catch (errLocation) {
  //     console.log('==== errLocation :', errLocation);
  //   }
  // }
}

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Injectable } from '@angular/core';
import { AppStorageKey } from '../models/enums/app-constant';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(private storage: NativeStorage) { }

    async set(key: AppStorageKey, value: any) {
        await this.storage.setItem(key, value);
    }

    get(key: AppStorageKey) {
        return this.storage.getItem(key);
    }

    async clear(keys: Array<string>) {
        if (!keys) { return; }
        if (keys.length === 1 && keys[0] === '*') { keys = Object.values(AppStorageKey); }
        keys = keys.filter(x => x !== AppStorageKey.currentUser);
        keys.forEach(async key => {
            await this.storage.remove(key);
        });
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ISearch, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public allSearches: ISearch[] = [];
  public selectedSearch: ISearch;

  constructor(
    private apiService: ApiService,
    private utilitiesService: UtilitiesService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getSearches(filter: IFilter): Observable<ISearch[]> {
    const setting: ISetting = {
      resource: 'searches',
      queryString: this.apiService.getQueryByFilter(filter),
    };
    this.selectedSearch = null;
    return this.apiService.get(setting).pipe(
        map((res: any) => {
          this.allSearches = res;
          return res;
        }),
        catchError((errGetSearches) => {
          console.error('==== search.service.ts errGetSearches :', errGetSearches);
          return this.exceptionService.catchBadResponse(errGetSearches);
        }),
        share(),
      );
  }

  public setFilter(filter: IFilter) {
    if (this.selectedSearch && this.selectedSearch.searchData) {
      filter.where = {};
      const savedFilter: IFilter = JSON.parse(this.selectedSearch.searchData);
      const whereKeys = Object.keys(savedFilter.where);
      if (whereKeys.length > 0) {
        whereKeys.forEach((whereKey) => {
          const value = savedFilter.where[whereKey];
          if (typeof value === 'number' || value.length > 0) {
            filter.where[whereKey] = value;
          }
        });
        this.utilitiesService.setFilterLookups(filter);
      }
    }
  }

}

import { Component, Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs/Subject';
// import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ToastController } from '@ionic/angular';

export interface ToastMessage {
  message: string;
}

@Injectable()
export class ToastService {
  public toastSubject = new Subject<ToastMessage>();
  public toastState = this.toastSubject.asObservable();

  constructor(
    @Optional() @SkipSelf() prior: ToastService,
    private toastController: ToastController,
    // public snackBar: MatSnackBar
  ) {
    if (prior) {
      return prior;
    }
  }

  public activate(message: string, mode: string, position?: any) { // change to 'any' to support compile
    this.toastSubject.next( { message });

    // this.snackBar.openFromComponent(ToasterComponent, {
    //   duration: 1000,
    // });

    // const config = new MatSnackBarConfig();
    // config.duration = 5000;
    // if (mode) {
    //   config.panelClass  = ['toast-' + mode];
    // }

    // this.snackBar.open(message, 'OK', config);
    if (mode === 'error') {
      mode = 'danger';
    }
    let activePosition: any = 'bottom';
    if (position) {
      activePosition = position;
    }
    this.toastController.create({
      message,
      duration: 3000,
      color: mode,
      position: activePosition,
    }).then((toast) => {
      toast.present();
    });
  }
}

export const environment = {
  device: true,
  production: true,
  api: '',
  firebase: {
    apiKey: 'AIzaSyAf988As0iDJI3K1Lj8I_BCsoG5se1SxZw',
    authDomain: 'ball-30fb1.firebaseapp.com',
    projectId: 'ball-30fb1',
    storageBucket: 'ball-30fb1.appspot.com',
    messagingSenderId: '113457319049',
    appId: '1:113457319049:web:6fd71e2296880f3d0bca58'
  }
};

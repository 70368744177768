import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IFilter } from './api.service';
import { DistrictService } from './district.service';
import { FieldService } from './field.service';
import { LevelService } from './level.service';
import { Platform } from '@ionic/angular';
import { BroadcastService } from './broadcast.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  public customDayShortNames = ['Chủ Nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];
  public currentPlatform = '';
  public isWeb = false;
  public scrolled = false;
  public isLoadingRequiredData = true;
  public isMobile = false;

  constructor(
    private router: Router,
    private districtService: DistrictService,
    private levelService: LevelService,
    private fieldService: FieldService,
    private broadcastService: BroadcastService,
  ) {
  }

  public handleScroll($event) {
    const scrollTop = $event.detail?.scrollTop;
    if (scrollTop && (scrollTop > 100 || scrollTop < 20)) {
      this.broadcastService.broadcast('scroll', {
        scrollTop,
      });
      if (scrollTop > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    }
  }

  public setFilterLookups(filter: IFilter) {
    this.setFilterLookupsForKey(filter, 'fields');
    this.setFilterLookupsForKey(filter, 'levels');
    this.setFilterLookupsForKey(filter, 'districts');
  }

  public getDateFromString(timeStr: string, format?: string): Date {
    const date = moment(timeStr, format).toDate();
    return date;
  }

  public getTodayStr(dateStr: any): string {
    if (!dateStr) {
      return '';
    }
    const date = this.getDateFromString(dateStr, 'YYYY-MM-DD HH:mm:ss.SSS');
    date.setHours(date.getHours() + 7); // GMT+7
    const currentDate = new Date();
    if (date && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
      if (date.getDate() === currentDate.getDate()) {
        return 'Hôm nay - ';
      }
      if (date.getDate() === (currentDate.getDate() - 1)) {
        return 'Hôm qua - ';
      }
      if (date.getDate() === (currentDate.getDate() + 1)) {
        return 'Ngày mai - ';
      }
    }
    return '';
  }

  public async setFilterLookupsForKey(filter: IFilter, key) {
    return new Promise(async (resolve, reject) => {
      if (!filter.where.lookups) {
        filter.where.lookups = {};
      }
      if (!filter.where[key]) {
        return;
      }
      let allObj = [];
      if (key === 'fields') {
        allObj = this.fieldService.allFields;
      } else if (key === 'levels') {
        allObj = this.levelService.allLevels;
      } if (key === 'districts') {
        allObj = this.districtService.allDistricts;
      }
      if (Array.isArray(filter.where[key])) {
        filter.where.lookups[key] = [];
      }

      const notFoundIds = [];
      if (Array.isArray(filter.where[key])) {
        for (const whereKey of filter.where[key]) {
          let found = false;
          allObj.forEach((allItem) => {
            if (allItem.id === whereKey) {
              filter.where.lookups[key].push(allItem);
              found = true;
            }
          });
          if (!found && whereKey !== '0') {
            notFoundIds.push(whereKey);
          }
        }
        if (notFoundIds.length > 0) {
          let founds = null;
          const filterObj: IFilter = {
            where: {
              id: notFoundIds
            }
          };
          if (key === 'fields') {
            founds = await this.fieldService.getFields(filterObj, false).toPromise();
          } else if (key === 'districts') {
            founds = await this.districtService.getDistricts(filterObj).toPromise();
          } else if (key === 'levels') {
            founds = await this.levelService.getLevels(filterObj).toPromise();
          }
          if (founds && founds.length > 0) {
            if (!filter.where.lookups[key] || filter.where.lookups[key].length === 0) {
              filter.where.lookups[key] = founds;
            } else {
              filter.where.lookups[key] = filter.where.lookups[key].concat(founds);
            }
            allObj = allObj.concat(founds);
          }
        }
      } else {
        let found = false;
        allObj.forEach((allItem) => {
          if (allItem.id === filter.where[key]) {
            filter.where.lookups[key] = allItem;
            found = true;
          }
        });
        if (!found) {
          let founds = null;
          const filterObj: IFilter = {
            where: {
              id: [filter.where[key]]
            }
          };
          if (key === 'fields') {
            founds = await this.fieldService.getFields(filterObj, false).toPromise();
          } else if (key === 'districts') {
            founds = await this.districtService.getDistricts(filterObj).toPromise();
          } else if (key === 'levels') {
            founds = await this.levelService.getLevels(filterObj).toPromise();
          }
          if (founds && founds.length > 0) {
            filter.where.lookups[key] = founds[0];
            allObj = allObj.concat(founds);
          }
        }
      }
      resolve(null);
    });
  }

  public checkMobileView() {
    const innerWidth: number = window.innerWidth;
    if (innerWidth < 900) {
      this.isMobile = true;
      return;
    }
    this.isMobile = false;
  }

  public getIonDate(year, month, date) {
    return '' + year + '-' + month + '-' + date;
  }

  public emptyUrlParams() {
    this.router.navigate([], {
      queryParams: {
        key: null,
        logo: null,
        view: null,
        archived: null,
        videoId: null,
      },
      queryParamsHandling: 'merge'
    });
  }
}

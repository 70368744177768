import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { IField, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import { FieldModalComponent } from '../shared/modal/field/field-modal';
import { ConfirmModalComponent } from '../shared/modal/confirm/confirm-modal';
import { DistrictService } from './district.service';

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  public allFields: IField[] = [];

  constructor(
    private router: Router,
    private modalController: ModalController,
    private apiService: ApiService,
    private districtService: DistrictService,
    private exceptionService: ExceptionService,
  ) {
  }

  public addOrEditField(field: IField, options?: {
    showAlert?: boolean;
    isAdminView?: boolean;
  }): Observable<IField> { // type === partner, team, player
    return new Observable(observer => {
      this.modalController.create({
        component: FieldModalComponent,
        componentProps: {
          field,
          isAdminView: options?.isAdminView,
        },
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then((detail) => {
          if (!detail || !detail.data || !detail.data.field) {
            observer.next(null);
          } else {
            const savedField = detail.data.field;
            if (field.id) {
              this.update(field).subscribe((resUpdate) => {
                observer.next(resUpdate);
              });
            } else {
              this.apiService.post({
                resource: 'fields',
                payload: savedField,
              }).subscribe((resField: {
                createdField?: IField;
                existingField?: IField;
              }) => {
                if (resField.existingField) {
                  field = JSON.parse(JSON.stringify(resField.existingField));
                  let alertExisting = 'Sân đã tồn tại với tên : ' + field.name;
                  if (field.name1) {
                    alertExisting += (', ' + field.name1);
                  }
                  if (field.name2) {
                    alertExisting += (', ' + field.name2);
                  }
                  alert(alertExisting);
                } else if (resField.createdField) {
                  field = JSON.parse(JSON.stringify(resField.createdField));
                  if (options?.showAlert) {
                    alert('Thêm sân thành công');
                  }
                }
                this.getFields({
                  where: {
                    district: field.district,
                  }
                }, true).subscribe(() => {
                  observer.next(field);
                });
              }, (errField) => {
                console.error('errField = ', errField);
                alert('Thêm sân không thành công');
                return this.exceptionService.catchBadResponse(errField);
              });
            }
          }
        });
      });
    });
  }

  public update(field: IField): Observable<IField> {
    return this.apiService.put({
      resource: 'fields/' + field.id,
      payload: field,
    });
  }

  public delete(field: IField) {
    return new Observable(observer => {
      if (!field) {
        return;
      }
      this.modalController.create({
        component: ConfirmModalComponent,
        componentProps: {
        },
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then((detail) => {
          if (detail && detail.data) {
            const confirmText = detail.data.confirmText;
            if (confirmText && (confirmText.toLowerCase() === 'yes' || confirmText.toLowerCase() === 'y')) {
              this.update(field).subscribe((resField) => {
                observer.next(resField);
              });
            }
          }
        });
      });
    });
  }

  public getFields(filter: IFilter, setAllFields: boolean): Observable<IField[]> {
    if (!filter.where) {
      filter.where = {};
    }
    if (!filter.where.districts || filter.where.districts.length === 0) {
      filter.where.districts = [];
      this.districtService.allDistricts.forEach((district) => {
        filter.where.districts.push(district.id);
      });
    }
    const setting: ISetting = {
      resource: 'fields',
      queryString: this.apiService.getQueryByFilter(filter),
    };
    return this.apiService.get(setting).pipe(
        map((res: any) => {
        if (setAllFields) {
          this.allFields = res;
          this.allFields.push({
            id: 0,
            name: 'Chưa có sân',
          });
        }
        return res;
      }),
        catchError((errGetFields) => {
          console.log('==== errGetFields :', errGetFields);
          return this.exceptionService.catchBadResponse(errGetFields);
        }),
        share(),
      );
  }
}

export enum AppEvent {
    login = 'user:login',
    logout = 'user:logout'
}

export enum AppChannel {
    info = 'Info'
}

export enum AppStorageKey {
    accessToken = 'access_token',
    isDarkModeEnabled = 'IsDarkModeEnabled',
    currentUser = 'CurrentUser'
}

export enum AppPagePath {
    login = '/login',
    account = '/app/tabs/account',
    home = '/app/tabs/home'
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ILevel, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  public allLevels: ILevel[] = [];

  constructor(
    private router: Router,
    private modalController: ModalController,
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getLevels(filter: IFilter): Observable<ILevel[]> {
    const $this = this;
    const url = '/api/levels';

    const setting: ISetting = {
      resource: 'levels',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
        map((res: any) => res),
        catchError((errGetLevels) => {
          console.log('==== errGetLevels :', errGetLevels);
          return this.exceptionService.catchBadResponse(errGetLevels);
        }),
        share(),
      );
  }

}

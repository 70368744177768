import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IComment, ICommentLookup } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  public allComments: IComment[] = [];
  public currentCommentId: number;

  constructor(
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getComments(filter: IFilter): Observable<ICommentLookup[]> {
    const setting: ISetting = {
      resource: 'comments',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
        map((res: any) => res),
        catchError((errGetComments) => {
          console.log('==== errGetComments :', errGetComments);
          return this.exceptionService.catchBadResponse(errGetComments);
        }),
        share(),
      );
  }

}

import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicSelectableModule } from 'ionic-selectable';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BroadcastService } from './services/broadcast.service';

// storage module
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { PostService } from './services/post.service';
import { LoginService } from './services/login-service';
import { LocalStorageService } from './services/local-storage-service';
import { AccountService } from './services/account-service';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { ExceptionService } from './services/exception.service';
import { ToastService } from './services/toast.service';
import { FieldService } from './services/field.service';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { CityService } from './services/city.service';
import { DistrictService } from './services/district.service';
import { UserService } from './services/user.service';
import { LevelService } from './services/level.service';
import myLocaleVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
import { AiApiService } from './services/ai.api.service';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SearchService } from './services/search.service';
import { AuthAdminGuard } from './services/auth-admin.guard';
import { AuthGuard } from './services/auth.guard';

registerLocaleData(myLocaleVi);

export let appInjector: Injector;


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,
    CommonModule, FormsModule, SharedModule, IonicModule, IonicSelectableModule
  ],
  providers: [EmailComposer, NativeStorage, BroadcastService, ApiService, ToastService,
    PostService, LoginService, LocalStorageService, AccountService, ExceptionService,
    FieldService, CityService, DistrictService, UserService, LevelService, AiApiService,
    Geolocation, SearchService, AuthAdminGuard, AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'vi' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }
}

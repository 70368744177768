import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IAffiliate, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService {
  public allAffiliates: IAffiliate[] = [];
  public leftAffiliates: IAffiliate[] = [];
  public rightAffiliates: IAffiliate[] = [];
  public centerAffiliates: IAffiliate[] = [];
  public currentAffiliateId: number;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getAffiliates(filter: IFilter): Observable<IAffiliate[]> {
    const $this = this;
    const setting: ISetting = {
      resource: 'affiliates',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
      map((res: any) => res),
        catchError((errGetAffiliates) => {
          console.log('==== errGetAffiliates :', errGetAffiliates);
          return this.exceptionService.catchBadResponse(errGetAffiliates);
        }),
        share(),
      );
  }

}

import 'rxjs/add/operator/map';

import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';

export interface ISetting {
  authorization?: string;
  queryString?: string;
  payload?: any;
  url?: string;
  id?: string;
  resource?: string;
  method?: string;
}

export interface IFilter {
  where?: any;
  search?: string;
  fields?: any;
  limit?: number;
  skip?: number;
  sortField?: string;
  sortOrder?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public token: string = null;

  constructor(private http: HttpClient) {
  }

  public get<T>(setting: ISetting): Observable<T> {
    return this.http.get<T>(this.formatUrl(setting), this.setHeaders());
    // return this.http.get<T>(this.formatUrl(setting));
  }

  public post<T>(setting: ISetting): Observable<T> {
    return this.http.post<T>(this.formatUrl(setting), setting.payload, this.setHeaders());
  }

  public upload<T>(setting: any): any {
    // create form data for file
    const form = new FormData();

    form.append('file', setting.payload.file, setting.payload.name);

    // upload file and report progress
    const req = new HttpRequest('POST', this.formatUrl(setting), form, {
      reportProgress: true,
    });

    return this.http.request(req);
  }

  public put<T>(setting: ISetting): Observable<T> {
    return this.http
      .put<T>(this.formatUrl(setting), setting.payload, this.setHeaders());
  }

  public patch<T>(setting: ISetting): Observable<T> {
    return this.http
      .patch<T>(this.formatUrl(setting), setting.payload, this.setHeaders());
  }

  public delete<T>(setting: ISetting): Observable<T> {
    return this.http.delete<T>(this.formatUrl(setting), this.setHeaders());
  }

  public getQueryByFilter(filter: IFilter): string {
    const requestOptions = {
      queryParams: [{
        key: 'filter',
        value: {
          limit: 1000,
        },
      }],
    };

    if (filter) {
      requestOptions.queryParams[0].value = Object.assign(requestOptions.queryParams[0].value, filter);
    }

    let query = '';

    if (requestOptions && requestOptions.queryParams) {
      requestOptions.queryParams.forEach((option) => {
        if (typeof option.value === 'string') {
          query += '&' + option.key + '=' + option.value;
        } else {
          query += '&' + option.key + '=' + encodeURIComponent(JSON.stringify(option.value));
        }
      });
    }
    return query;
  }

  private formatUrl(setting: ISetting): string {
    let url = environment.api + '/api/';
    if (setting.url) {
      url = setting.url;
    }
    if (setting.resource) {
      url += setting.resource;
    }

    if (setting.id) {
      url += '/' + setting.id;
    }

    if (setting.method) {
      url += '/' + setting.method;
    }

    if (setting.queryString) {
      url += '?' + setting.queryString;
    }

    return url;
  }

  private setHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        ['Content-Type']: 'application/json',
      }),
    };

    let token: any = localStorage.getItem('token');
    if (token) {
      if (!token) {
        token = '';
      } else {
        token = 'Bearer ' + token;
      }

      httpOptions = {
        headers: new HttpHeaders({
          ['Content-Type']: 'application/json',
          ['Authorization']: token,
          // ['Access-Control-Allow-Origin']: '*',
          // ['Access-Control-Allow-Methods']: 'GET, POST, DELETE, PUT',
        }),
      };
    }
    return httpOptions;
  }
}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    console.log(JSON.stringify(req.headers));
    return next.handle(req);
  }
}

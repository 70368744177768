import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AccountService } from 'src/app/services/account-service';
import { IFilter } from 'src/app/services/api.service';
import { CityService } from 'src/app/services/city.service';
import { DistrictService } from 'src/app/services/district.service';
import { FieldService } from 'src/app/services/field.service';
import { LevelService } from 'src/app/services/level.service';
import { PostService } from 'src/app/services/post.service';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IDistrict, IField, IPost, IPostLookup } from '../../interfaces';
import { LoginService } from 'src/app/services/login-service';

@Component({
  selector: 'app-post-modal',
  templateUrl: './post-modal.html',
  styleUrls: ['./post-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PostModalComponent implements OnDestroy, OnInit {
  @Input() public post: IPostLookup;
  @Input() public isAdminView: boolean;
  public loading = false;
  public minDate = null;
  public maxDate = null;

  public startDate = null;
  public startTime = null;

  constructor(
    private modalController: ModalController,
    public postService: PostService,
    public fieldService: FieldService,
    public districtService: DistrictService,
    public levelService: LevelService,
    public loginService: LoginService,
    public utilitiesService: UtilitiesService,
    public cityService: CityService,
    public userService: UserService,
  ) {
    const $this = this;
  }

  public async ngOnInit() {
    this.minDate = new Date().toISOString();
    this.maxDate = (new Date()).getFullYear() + 1;
    if (!this.post.lookups) {
      this.post.lookups = {};
    }
    if (this.post.date) {
      if (typeof this.post.date === 'string') {
        this.startDate = this.post.date;
        this.startTime = this.post.date;
      } else {
        this.startDate = this.post.date.toUTCString();
        this.startTime = this.post.date.toUTCString();
      }
    }
  }

  public ngOnDestroy() {
    //
  }
  ionViewWillEnter() {
  }

  public addField() {
    const newField: IField = {
      status: 'pending',
    };
    if (this.post.districts?.length > 0) {
      newField.district = this.post.districts[0];
    }
    this.fieldService.addOrEditField(newField, {
      showAlert: false,
    }).subscribe((field: IField) => {
      if (this.post.districts.indexOf(field.district) < -1) {
        this.post.districts.push(field.district);
      }
      if (!this.post.fields) {
        this.post.fields = [];
      }
      this.post.fields.push(field.id);
      if (!this.post.lookups) {
        this.post.lookups = {};
      }
      if (!this.post.lookups.fields) {
        this.post.lookups.fields = [];
      }
      this.post.lookups.fields.push(field);
    });
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async save() {
    // if (this.post.postType === 'partner' && (!this.startDate || !this.startTime)) {
    //   alert('Các thông tin không được để trống: Ngày, Giờ');
    //   return;
    // }
    let date = new Date();
    if (this.startDate) {
      date = new Date(this.startDate);
    }

    const timeStart = new Date(this.startTime);
    date.setHours(timeStart.getHours());
    date.setMinutes(timeStart.getMinutes());
    this.post.date = date;

    await this.modalController.dismiss({
      post: this.post,
    });
  }

  public clearTime($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.startTime = null;
  }

  public clearDate($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.startDate = null;
  }

  public changeMultiSelect(fieldName: string) { // fields, districts, levels
    if (this.post.lookups[fieldName]) {
      this.post[fieldName] = [];
      if (fieldName === 'fields') {
        this.post.districts = [];
      }
      this.post.lookups[fieldName].forEach((fieldItem) => {
        this.post[fieldName].push(fieldItem.id);
        if (fieldName === 'fields') {
          this.post.districts.push(fieldItem.district);
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { IUser, IPost } from '../shared/interfaces';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private router: Router,
    private modalController: ModalController,
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public addUser(user: IUser, options?: {
    showAlert?: boolean;
    isAdminView?: boolean;
  }): Observable<IUser> {
    return new Observable(observer => {
      this.apiService.post({
        resource: 'users',
        payload: user,
      }).subscribe((resUser) => {
        observer.next(user);
      }, (errUser) => {
        console.error('errUser = ', errUser);
        alert('Thêm user không thành công');
        return this.exceptionService.catchBadResponse(errUser);
      });
    });
  }

  public update(user: IUser): Observable<IUser> {
    return this.apiService.put({
      resource: 'users/' + user.id,
      payload: user,
    });
  }

  public getUsers(filter: IFilter): Observable<IUser[]> {
    const setting: ISetting = {
      resource: 'users',
      queryString: this.apiService.getQueryByFilter(filter),
    };

    return this.apiService.get(setting).pipe(
        map((res: any) => res),
        catchError((errGetUsers) => {
          console.log('==== errGetUsers :', errGetUsers);
          return this.exceptionService.catchBadResponse(errGetUsers);
        }),
        share(),
      );
  }

}

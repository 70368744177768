import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {
  Router,
} from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm/confirm-modal';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ApiService, IFilter } from 'src/app/services/api.service';
import { PostService } from 'src/app/services/post.service';
import { IPost } from '../../interfaces';
import { LoginService } from 'src/app/services/login-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy,OnInit {
  public loading = false;

  private storage: NativeStorage;
  private subscriptions = [];

  constructor(
    private router: Router,
    private modalController: ModalController,
    public actionSheetController: ActionSheetController,
    private broadcastService: BroadcastService,
    private loginService: LoginService,
  ) {
    this.storage = new NativeStorage();
  }

  ngOnInit() {

  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }


  async dismiss() {
    await this.modalController.dismiss();
  }

  public async login(type) {
    if (type === 'google') {
      await this.loginService.loginViaGoogle();
    } else if (type === 'facebook') {
      await this.loginService.loginViaFacebook();
    }
    await this.modalController.dismiss({
      type,
    });
  }

}

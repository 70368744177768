import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { IPost } from '../shared/interfaces';
import { PostModalComponent } from '../shared/modal/post/post-modal';
import { ConfirmModalComponent } from '../shared/modal/confirm/confirm-modal';
import { AccountService } from './account-service';
import { ApiService, IFilter, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';
import { CityService } from './city.service';
import { DistrictService } from './district.service';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  public allPosts: IPost[] = [];

  constructor(
    private districtService: DistrictService,
    private modalController: ModalController,
    private cityService: CityService,
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getPosts(filter: IFilter, options?: {
    manually?: boolean;
    searchService?: SearchService;
    currentUserId?: number;

  }): Observable<IPost[]> {
    const $this = this;

    // ids to array
    if (filter.where.id && filter.where.id.length > 0 && filter.where.id.indexOf(',') > -1) {
      filter.where.id = filter.where.id.split(',');
    }

    if (!filter.where.districts) {
      filter.where.districts = [];
    }
    if (filter.where.districts.length === 0
      && (!filter.where.id || filter.where.id.length === 0)) {
      this.districtService.allDistricts.forEach((districtItem) => {
        filter.where.districts.push(districtItem.id);
      });
    }

    const filterCloned = JSON.parse(JSON.stringify(filter));
    delete filterCloned.where.lookups;
    let queryString = this.apiService.getQueryByFilter(filterCloned);
    if (options?.manually) {
      queryString += ('&manually=true');
    }
    const setting: ISetting = {
      resource: 'posts',
      queryString,
    };
    return this.apiService.get(setting)
      .pipe(
        map((data: IPost[]) => {
          this.allPosts = data;
          if (options?.manually) {
            options.searchService.getSearches({
              where: {
                postType: filter.where.postType,
                userId: options.currentUserId,
              }
            }).subscribe(() => {});
          }
          return data;
        }), catchError((errGetPosts) => {
          console.log('==== errGetPosts :', errGetPosts);
          alert('Phiên đăng nhập hết hiệu lực. Trang sẽ tự động tải lại.');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return $this.exceptionService.catchBadResponse(errGetPosts);
        }),
      );
  }

  public add(post: IPost) {
    if (!post.postType) {
      post.postType = 'partner';
    }
    if (!post.cityId) {
      post.cityId = this.cityService.currentCityId;
    }
    return new Observable(observer => {
      this.modalController.create({
        component: PostModalComponent,
        componentProps: {
          post,
        },
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then((detail) => {
          if (!detail || !detail.data || !detail.data.post) {
            observer.next(null);
          } else {
            const savedPost = detail.data.post;
            console.log('savedPost = ', savedPost);
            this.apiService.post({
              resource: 'posts',
              payload: savedPost,
            }).subscribe((resPost: any) => {
              // alert('Tạo bài viết thành công');
              // this.allPosts.unshift(resPost);
              observer.next(resPost);
            }, (errPost) => {
              console.error('errPost = ', errPost);
              // alert('Tạo bài viết không thành công');
              return this.exceptionService.catchBadResponse(errPost);
            });
          }
        });
      });
    });
  }

  public edit(post: IPost, isAdminView?: boolean) {
    return new Observable(observer => {
      this.modalController.create({
        component: PostModalComponent,
        componentProps: {
          post,
          isAdminView,
        },
        cssClass: 'fullscreen',
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then((detail) => {
          if (!detail || !detail.data || !detail.data.post) {
            observer.next(null);
          } else {
            const savedPost = detail.data.post;
            this.apiService.put({
              resource: 'posts/' + post.id,
              payload: savedPost,
            }).subscribe((resPost: any) => {
              // alert('Chỉnh sửa bài viết thành công');
              observer.next(resPost);
            }, (errPost) => {
              console.error('errPost = ', errPost);
              // alert('Chỉnh sửa bài viết không thành công');
              return this.exceptionService.catchBadResponse(errPost);
            });
          }
        });
      });
    });
  }

  public delete(postId: number) {
    return new Observable(observer => {
      if (!postId) {
        return;
      }
      this.modalController.create({
        component: ConfirmModalComponent,
        componentProps: {
        },
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then((detail) => {
          if (detail && detail.data) {
            const confirmText = detail.data.confirmText;
            if (confirmText && (confirmText.toLowerCase() === 'yes' || confirmText.toLowerCase() === 'y')) {
              this.apiService.delete({
                resource: 'posts/' + postId,
              }).subscribe((resPost: any) => {
                // alert('Xóa bài viết thành công');
                this.allPosts = this.allPosts.filter(el => el.id !== postId);
                observer.next(resPost);
              }, (errPost) => {
                console.error('errPost = ', errPost);
                // alert('Xóa bài viết không thành công');
                return this.exceptionService.catchBadResponse(errPost);
              });
            }
          }
        });
      });
    });
  }
}

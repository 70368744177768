import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ISetting } from './api.service';
import { catchError, map, share } from 'rxjs/operators';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root',
})
export class OthersService {
  public allVideos;

  constructor(
    private apiService: ApiService,
    private exceptionService: ExceptionService,
  ) {
  }

  public getVideos(): Observable<any> {
    const setting: ISetting = {
      resource: 'others/videos',
    };
    return this.apiService.get(setting).pipe(
      map((res: any) => res),
        catchError((errGetVideos) => {
          console.error('==== errGetVideos :', errGetVideos);
          return this.exceptionService.catchBadResponse(errGetVideos);
        }),
        share(),
      );
  }
}

import { Component, OnInit, OnDestroy, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DistrictService } from 'src/app/services/district.service';
import { LevelService } from 'src/app/services/level.service';
import { UserService } from 'src/app/services/user.service';
import { IField } from '../../interfaces';

@Component({
  selector: 'app-field-modal',
  templateUrl: './field-modal.html',
  styleUrls: ['./field-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class FieldModalComponent implements OnDestroy, OnInit {
  @Input() public field: IField;
  @Input() public isAdminView: boolean;

  public loading = false;
  public minDate: string = new Date().toISOString();
  public maxDate: any = (new Date()).getFullYear() + 1;

  public startTime: string = null;
  public startDate: string = null;
  public customDayShortNames = ['Chủ Nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];

  constructor(
    private modalController: ModalController,
    public districtService: DistrictService,
    public levelService: LevelService,
    public userService: UserService,
  ) {
    const $this = this;
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    //
  }
  ionViewWillEnter() {
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async save() {
    if (!this.field.name || !this.field.district) {
      alert('Các thông tin không được để trống: Tên, Quận(Huyện)');
      return;
    }
    await this.modalController.dismiss({
      field: this.field,
    });
  }


}

import {
  Component, OnInit, Input, TemplateRef, Output, EventEmitter,
} from '@angular/core';
import { IFilter } from 'src/app/services/api.service';
import { BroadcastService } from 'src/app/services/broadcast.service';

@Component({
  selector: 'app-omni-search-chip',
  templateUrl: './omni-search-chip.component.html',
  styleUrls: ['./omni-search-chip.component.scss'],
})
export class OmniSearchChipComponent implements OnInit {
  @Input() filterWhere;
  @Input() isAdminView: boolean;
  @Input() fieldName: string; // Keyword
  @Input() key: string; // keywords
  @Input() filterType: string; // string, array, checked

  @Output() public search = new EventEmitter<{
  }>();
  @Output() remove = new EventEmitter();

  public dateDisplay: string;
  public hourDisplay: string;
  public showRemoveButton = false;

  private subscriptions = [];

  constructor(
    private broadcastService: BroadcastService,
  ) {
    const subscription = this.broadcastService.subjectUniversal.subscribe((msg) => {
      if (msg.name === 'changeSearch') {
        this.filterWhere = msg.message.filter?.where;
        if (this.key === 'date') {
          this.setDateDisplay();
        } else if (this.key === 'hour') {
          this.setHourDisplay();
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnInit() {
    if (this.key === 'date') {
      this.setDateDisplay();
    } else if (this.key === 'hour') {
      this.setHourDisplay();
    }
  }

  public removeFilter() {
    this.remove.emit();
  }

  public isObject(val): boolean {
    return typeof val === 'object';
  }

  private setDateDisplay() {
    this.dateDisplay = '';
    if (this.filterWhere.date) {
      if (typeof this.filterWhere.date === 'string') {
        if (this.filterWhere.date.length > 15) { // 2024-05-15T00:00:00+07:00
          this.dateDisplay = this.filterWhere.date.split('T')[0];
          const dateArr = this.filterWhere.date.split('-');
          if (dateArr.length === 3) {
            this.dateDisplay = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
          }
        } else {
          const dateArr = this.filterWhere.date.split('-');
          if (dateArr.length === 3 && dateArr[0].length > 2) { // 2024-05-05
            this.dateDisplay = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
          }
        }
      } else {
        this.dateDisplay = this.filterWhere.date.getDate() + '-' +
          (this.filterWhere.date.getMonth() + 1) + '-' + this.filterWhere.date.getFullYear();
      }
    }
  }

  private setHourDisplay() {
    this.hourDisplay = '';
    if (this.filterWhere.hour) {
      this.hourDisplay = '' + this.filterWhere.hour + 'h';
      if (this.filterWhere.minute) {
        this.hourDisplay += this.filterWhere.minute;
      }
    }
  }
}

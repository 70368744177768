import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicSelectableModule } from 'ionic-selectable';

import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ConfirmModalComponent } from './modal/confirm/confirm-modal';
import { PostModalComponent } from './modal/post/post-modal';
import { ListPostsComponent } from './components/list-posts/list-posts.component';
import { LoginComponent } from './modal/login/login.component';
import { FieldModalComponent } from './modal/field/field-modal';
import { ResearchAIFilterComponent } from './components/research-ai-filter/research-ai-filter.component';
import { OmniSearchChipComponent } from './components/omni-search-chip/omni-search-chip.component';
import { SearchPostModalComponent } from './modal/search-post/search-post-modal';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { AffiliateComponent } from './components/affiliate/affiliate.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IonicSelectableModule,
  ],
  exports: [
    CommonModule,
    PostModalComponent,
    SearchPostModalComponent,
    FieldModalComponent,
    ConfirmModalComponent,
    ListPostsComponent,
    UserHeaderComponent,
    AffiliateComponent,
    OmniSearchChipComponent,
    ResearchAIFilterComponent,
    LoginComponent,
  ],
  declarations: [
    PostModalComponent,
    SearchPostModalComponent,
    ConfirmModalComponent,
    FieldModalComponent,
    ListPostsComponent,
    UserHeaderComponent,
    AffiliateComponent,
    OmniSearchChipComponent,
    ResearchAIFilterComponent,
    LoginComponent,
  ],
  entryComponents: [
    PostModalComponent,
    SearchPostModalComponent,
    FieldModalComponent,
    ListPostsComponent,
    UserHeaderComponent,
    AffiliateComponent,
    OmniSearchChipComponent,
    ResearchAIFilterComponent,
    LoginComponent,

  ],
})
export class SharedModule { }

import {
  Component, OnInit, Input, TemplateRef, Output, EventEmitter,
} from '@angular/core';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { UserService } from 'src/app/services/user.service';
import { IUser } from '../../interfaces';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { LoginService } from 'src/app/services/login-service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  @Input() user: IUser;
  @Input() date: Date;
  @Input() isComment = false;
  @Input() commentMessage = '';
  @Input() postStatus = '';
  @Input() display = ''; // menu, post, comment
  @Output() public undone = new EventEmitter();

  public today = '';
  private subscriptions = [];

  constructor(
    public userService: UserService,
    private broadcastService: BroadcastService,
    public loginService: LoginService,
    private utilitiesService: UtilitiesService,
  ) {
    const subscription = this.broadcastService.subjectUniversal.subscribe((msg) => {
    });
    this.subscriptions.push(subscription);
  }
  ngOnInit() {
    this.today = this.utilitiesService.getTodayStr(this.date);
    if (!this.user) {
      this.user = {
        avatar: 'https://ionicframework.com/docs/img/demos/avatar.svg',
        name: '**************',
      };
    }
  }
}
